import React from 'react'
import I18n from '../../utils/I18n'
import PrimaryMatchCard from './PrimaryMatchCard'
import SecondaryMatchCard from './SecondaryMatchCard'

const QuizResults = ({lang, scores}) => {
    const GATSBY_S3_PATH = process.env.GATSBY_S3_PATH
    const i18n = new I18n({lang})
    const downloadImageRole = scores[0].download
    const downloadImagePath = `${GATSBY_S3_PATH}/quizapp/downloads/${downloadImageRole}.jpg`

    const downloadResults = async () => {
        const response = await fetch(downloadImagePath)
        const blobImage = await response.blob()
        const href = URL.createObjectURL(blobImage)
        const anchorElement = document.createElement('a')
        const nameOfDownload = `my-career-match-${downloadImageRole}.png`

        anchorElement.href = href
        anchorElement.download = nameOfDownload
      
        document.body.appendChild(anchorElement)
        anchorElement.click()

        if (process.env.NODE_ENV === "production") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "eventTracker",
                eventCat: "Downloads",
                eventAct: "Image",
                eventLbl: nameOfDownload,
                'nonInteraction': 'false'
            })
        }
      
        document.body.removeChild(anchorElement)
        window.URL.revokeObjectURL(href)
    }

    const shareOnFacebook = () => {

    }

    const shareOnTwitter = () => {

    }

    const shareOnLinkedIn = () => {

    }

    return (
        <div style={{paddingBottom: '40px'}}>
            <div className="container slds-grid slds-wrap" grid="align-center">
                <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_3-of-5" style={{paddingRight: '25px'}}>
                    <h1 className="for-mobile-large" type-style="display-2" style={{paddingBottom: '5px !important'}}>{i18n.text('quiz_results_title')}</h1>
                    <p type-style="body-2" className="center-on-mobile" style={{paddingBottom: '20px'}}>{i18n.text('quiz_results_description')}</p>
                </div>
                <div className="hide-on-mobile slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_2-of-5">
                    <img
                        className="move-up"
                        src={`${GATSBY_S3_PATH}/career-path-v3/career-match/images/results-hero.png`}
                        alt="Astro in Trailhead hat with hands up at the top of a trail celebrating."
                    />
                </div>
            </div>

            <div className="container">
                <PrimaryMatchCard lang={lang} data={scores[0]} />

                <h2 type-style="display-3" style={{fontSize: '24px'}}>{i18n.text('quiz_explore_title')}</h2>

                <div className="slds-grid slds-wrap slds-grid_pull-padded slds-grid_vertical-stretch">
                    <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3 slds-p-horizontal_small">
                        <SecondaryMatchCard lang={lang} data={scores[1]} rank="2" />
                    </div>

                    <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3 slds-p-horizontal_small">
                        <SecondaryMatchCard lang={lang} data={scores[2]} rank="3" />
                    </div>

                    <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3 slds-p-horizontal_small">
                        <div className="download-card">
                            <h2 type-style="display-3" style={{fontSize: '2rem; text-align: center'}}>{i18n.text('quiz_download_and_share_title')}</h2>
                            
                            <p type-style="body-1" style={{padding: '0 0 25px'}}>{i18n.text('quiz_download_and_share_description')}</p>
                            
                            <button onClick={downloadResults} className="btn btn-blue">{i18n.text('quiz_button_download')}</button>

                            <div className="social-buttons hide-on-mobile-flex">
                                <button onClick={shareOnFacebook} className="btn btn-secondary">{i18n.text('quiz_button_facebook')}</button>
                                <button onClick={shareOnTwitter} className="btn btn-secondary">{i18n.text('quiz_button_twitter')}</button>
                                <button onClick={shareOnLinkedIn} className="btn btn-secondary">{i18n.text('quiz_button_linkedin')}</button>

                                {
                                    /*
                                    <ShareNetwork
                                    network="facebook"
                                    :url="store.share_url"
                                    title="Trailblazer Career Match"
                                    hashtags="Trailhead,TrailblazerCommunity"
                                    quote="Identify your career matches in the Salesforce ecosystem - unlock your path to professional success with @Trailhead! Take the free quiz #TrailblazerCommunity at trailhead.com/career-match"
                                    :popup="{ width: 700, height: 500 }"
                                    >
                                    <wes-button variant="secondary" size="default"> Facebook</wes-button>
                                    </ShareNetwork>
                                    <ShareNetwork
                                    network="twitter"
                                    :url="store.share_url"
                                    title="Trailblazer Career Match"
                                    hashtags="Trailhead,TrailblazerCommunity"
                                    :popup="{ width: 700, height: 650 }"
                                    >
                                    <wes-button variant="secondary" size="default"> Twitter</wes-button>
                                    </ShareNetwork>
                                    <ShareNetwork
                                    network="linkedin"
                                    :url="store.share_url"
                                    title="Trailblazer Career Match"
                                    hashtags="Trailhead,TrailblazerCommunity"
                                    :popup="{ width: 700, height: 650 }"
                                    >
                                    <wes-button variant="secondary" size="default"> LinkedIn</wes-button>
                                    </ShareNetwork>
                                    */
                                }
                            </div>

                            <div className="social-buttons hide-on-desktop-flex">

                                <button onClick={shareOnFacebook()} className="btn btn-secondary">{i18n.text('quiz_button_facebook')}</button>
                                <button onClick={shareOnTwitter()} className="btn btn-secondary">{i18n.text('quiz_button_twitter')}</button>
                                <button onClick={shareOnLinkedIn()} className="btn btn-secondary">{i18n.text('quiz_button_linkedin')}</button>

                                {
                                    /*
                                    <ShareNetwork
                                    network="facebook"
                                    :url="store.share_url"
                                    title="Trailblazer Career Match"
                                    hashtags="Trailhead,TrailblazerCommunity"
                                    quote="Identify your career matches in the Salesforce ecosystem - unlock your path to professional success with @Trailhead! Take the free quiz #TrailblazerCommunity at trailhead.com/career-match"
                                    :popup="{ width: 700, height: 500 }"
                                    >
                                    <wes-button variant="secondary" size="small"> Facebook</wes-button>
                                    </ShareNetwork>
                                    <ShareNetwork
                                    network="twitter"
                                    :url="store.share_url"
                                    title="Trailblazer Career Match"
                                    hashtags="Trailhead,TrailblazerCommunity"
                                    :popup="{ width: 700, height: 650 }"
                                    >
                                    <wes-button variant="secondary" size="small"> Twitter</wes-button>
                                    </ShareNetwork>
                                    <ShareNetwork
                                    network="linkedin"
                                    :url="store.share_url"
                                    title="Trailblazer Career Match"
                                    hashtags="Trailhead,TrailblazerCommunity"
                                    :popup="{ width: 700, height: 650 }"
                                    >
                                    <wes-button variant="secondary" size="small"> LinkedIn</wes-button>
                                    </ShareNetwork>
                                    */
                                }
                            </div>

                            <a 
                                href="https://trailhead.salesforce.com/trailblazer-community/groups/0F9300000001omnCAA?tab=discussion&sort=LAST_MODIFIED_DATE_DESC" 
                                target="_blank" 
                                className="btn btn-secondary">
                                    {i18n.text('quiz_button_trailblazer')}
                            </a>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuizResults
