import React from 'react'

const QuizProgressBar = ({ currentStep, stepsData }) => {

    return (
      <div style={{padding: '32px 0px', background: 'rgb(243, 247, 255)'}}>
        <div className="container">
          
          <p type-style="body-3" className="text-center" style={{paddingBottom: '25px', color: 'rgb(1, 68, 134)'}}>
            {stepsData[currentStep-1].preText}
          </p>

          <div className="nav-progress">
            {stepsData.map((stepData, index) => {
              return (
                <div key={`progress-item-${index}`} className={`progress-item ${currentStep > index ? 'complete' : ''}`}>
                  <span>
                    <span className="circle">
                      <span>{index+1}</span>
                    </span>
                    {stepData.name}
                  </span>
                  <div className="arrow-wrapper">
                    <div className="arrow-cover">
                      <div className="arrow"></div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
}

export default QuizProgressBar
