import React from 'react'
import I18n from '../../utils/I18n'

const SecondaryMatchCard = ({lang, data, rank}) => {
    const GATSBY_S3_PATH = process.env.GATSBY_S3_PATH
    const i18n = new I18n({lang})

    const lernMore = () => {
        if (process.env.NODE_ENV === "production") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "eventTracker",
                eventCat: "Outbound Link Clicks",
                eventAct: "Learn More",
                eventLbl: data.url,
                nonInteraction: 'false'
            })
        }
    }

    return (
        <div className="secondary-match-card">
            <div style={{textAlign: 'center', padding: '0 10px'}}>
                <img src={`${GATSBY_S3_PATH}/career-path-v3/career-match/images/${data.image}`} alt="Trailblazers" />
            </div>

            <div size="1:1 3:4@medium 3:4@large" style={{padding: '0 15px', textAlign: 'center'}}>
                <div className="badge badge-green" style={{margin: '20px 0 0'}}>{i18n.text('quiz_career_match_number', rank)}</div>
                <h1 type-style="display-3" style={{fontSize: '24px', paddingTop: '0', marginTop: '-25px', paddingBottom: '0 !important'}}>
                    {data.main_title}
                </h1>
                <p type-style="body-3" className="mb-5 hide-on-mobile" style={{fontSize: '12px'}}>
                    {data.desktop_copy}
                </p>
                <div className="stat-block">
                    <div className="stat">
                        <img src="@/assets/money.png" alt="" />
                        <p className="slds-text-align_left">
                            {data.stats[0].stat}<br/>
                            {data.stats[0].stat_text}
                        </p>
                    </div>
                    <div className="stat">
                        <img src="@/assets/case.png" alt="" />
                        <p className="slds-text-align_left">
                            {data.stats[1].stat}<br/>
                            {data.stats[1].stat_text}
                        </p>
                    </div>
                </div>
                <div className="my-3">
                    <a href={data.url} onClick={lernMore} className="btn btn-secondary" target="_blank">{i18n.text('quiz_career_learn_more')}</a>
                    {
                        //  <wes-button variant="secondary" size="default" @click="learnMore(data.url)"> Learn More</wes-button>
                    }
                </div>
            </div>
        </div>
    )
}

export default SecondaryMatchCard
