import React, {useState} from 'react'
import I18n from '../../utils/I18n'

const PrimaryMatchCard = ({lang, data}) => {
    const GATSBY_S3_PATH = process.env.GATSBY_S3_PATH
    const i18n = new I18n({lang})

    const random_url_best_match = () => {
        return [data.url, data.url2].sort((a, b) => 0.5 - Math.random())[0]
    }

    const [learnMoreUrl, setLearnMoreUrl] = useState(random_url_best_match())

    const getStarted = () => {
        if (process.env.NODE_ENV === "production") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "eventTracker",
                eventCat: "Outbound Link Clicks",
                eventAct: "Get Started",
                eventLbl: learnMoreUrl,
                nonInteraction: 'false'
            })
        }
    }
    
    return (
        <div className="best-match-card">
            <div className="slds-grid slds-wrap">
                <div className="img-div slds-size_1-of-1 slds-medium-size_1-of-4">
                    <img src={`${GATSBY_S3_PATH}/career-path-v3/career-match/images/${data.image}`} alt="Trailblazers" />
                </div>
                <div className="slds-size_1-of-1 slds-medium-size_3-of-4" style={{padding: '0 25px'}}>
                    <div className="badges">
                        <div className="badge badge-grey">
                            <img className="badge-icon" src={`${GATSBY_S3_PATH}/career-path-v3/career-match/images/locker_service_console.png`} alt="" />
                            {i18n.text('quiz_best_match')}
                        </div>
                        <div className="badge badge-green">{i18n.text('quiz_career_match_number', 1)}</div>
                    </div>

                    <h1 className="for-mobile" type-style="display-3" style={{paddingBottom: '0 !important'}}>
                        {data.main_title}
                    </h1>

                    <div className="my-5 hide-on-desktop" style={{textAlign: 'center'}}>
                        <a href={learnMoreUrl} className="btn btn-blue" onClick={getStarted} target="_blank">{i18n.text('quiz_career_learn_more')}</a>
                        {
                            //<wes-button variant="primary" size="default" @click="getStarted()">Learn More</wes-button>
                        }
                    </div>

                    <p type-style="body-2" className="mb-5 hide-on-mobile">
                        {data.desktop_copy}
                    </p>

                    <p type-style="body-3" className="my-5 hide-on-desktop text-center" style={{marginBottom: '20px'}}>
                        {data.mobile_copy}
                    </p>

                    <div className="stat-block">
                        <div className="stat">
                            <img src={`${GATSBY_S3_PATH}/career-path-v3/career-match/images/money.png`} alt="" />
                            <p>{data.stats[0].stat} {data.stats[0].stat_text}</p>
                        </div>
                        <div className="stat">
                            <img src={`${GATSBY_S3_PATH}/career-path-v3/career-match/images/case.png`} alt="" />
                            <p>{data.stats[1].stat} {data.stats[1].stat_text}</p>
                        </div>
                        <div className="stat hide-stat-on-mobile">
                            <img src={`${GATSBY_S3_PATH}/career-path-v3/career-match/images/trending.png`} alt="" />
                            <p>{data.stats[2].stat } {data.stats[2].stat_text}</p>
                        </div>
                    </div>

                    <div className="my-5 hide-on-mobile">
                        <a href={learnMoreUrl} className="btn btn-blue" onClick={getStarted} target="_blank">{i18n.text('quiz_career_get_started')}</a>
                        {
                            //<wes-button variant="primary" size="default" @click="getStarted()">Get Started</wes-button>
                        }
                    </div>

                    <p type-style="body-3">
                        <b>{i18n.text('quiz_career_top_skills')}</b>
                    </p>

                    <p type-style="body-3" className="hide-on-mobile mt-1" style={{color: '#585858'}}>
                        {data.top_skills}
                    </p>

                    <ul className="skills-list-mobile hide-on-desktop">
                        <li v-for="skill in match.top_skills_mobile" key="skill">{/*skill*/}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PrimaryMatchCard
