import React from 'react'

const QuizHeader = ({ title, description, currentStep, stepsData }) => {

  return (
    <div className="container" grid="align-center" axis="vertical" flow="wrap">
      <div size="1:1 1:2@medium 2:4@large" className="slds-size_1-of-1 slds-medium-size_1-of-2" style={{paddingRight: '25px'}}>
        <h1 className="for-mobile-large" type-style="display-1">{title}</h1>
        <p type-style="body-3" className="mb-5">{description}</p>
      </div>
      <div size="1:1 1:2@medium 2:4@large" className="slds-size_1-of-1 slds-medium-size_1-of-2">
        <img 
          src={`https://developer.salesforce.com/resources2/career-path-v3/match/images/${stepsData[currentStep-1].image}`}
          alt={stepsData[currentStep-1].imageAlt}
        />
      </div>
    </div>
  )
}

export default QuizHeader
